import React from 'react';
import Search from './search';
function QuickLinks({
  setShowMenu,
  showMenu,
  showSearch,
  setShowSearch,
  renderAsMobile,
  cartQuantity,
  trackSecondaryNavigation,
}) {
  const { MyAccountLandingPageURL, ShoppingCartLandingPageURL } = { ...window._THULEDATA._MainNavigation };

  const searchBtn = () => {
    return (
      <button
        onClick={() => {
          setShowSearch(true);
          trackSecondaryNavigation('Search');
        }}
        className="quickLinks__btn btn"
      >
        <i className="le-icon-search"></i>
        <span className="sr-only">Search</span>
      </button>
    );
  };

  const goToCartBtn = () => {
    return (
      (_THULEDATA.isB2CEnabled || _THULEDATA.isExtranet) && (
        <a
          href={ShoppingCartLandingPageURL}
          className="quickLinks__btn btn cartLink"
          onClick={() => {
            trackSecondaryNavigation('Shoppingcart', ShoppingCartLandingPageURL);
          }}
        >
          <span className="cart-counter">{cartQuantity}</span>
          <i className="le-icon-shopping-cart" />
          <span className="sr-only">Go to cart</span>
        </a>
      )
    );
  };

  const myAccountBtn = () => {
    return (
      (_THULEDATA.isB2CEnabled || _THULEDATA.isExtranet) && (
        <a
          onClick={() => {
            trackSecondaryNavigation('My Account', MyAccountLandingPageURL);
          }}
          href={MyAccountLandingPageURL}
          className="quickLinks__btn"
        >
          <i className="le-icon-user"></i>
          <span className="sr-only">My account</span>
        </a>
      )
    );
  };

  const toggleMenuBtn = () => {
    return (
      <button className="quickLinks__btn btn--toggleMenu btn" onClick={() => setShowMenu(!showMenu)}>
        <i className={`le-icon-${showMenu ? 'x' : 'menu'}`} />
        <span className="sr-only">Toggle menu</span>
      </button>
    );
  };
  return (
    <div className="d-flex quickLinks">
      {searchBtn()}
      {myAccountBtn()}
      {goToCartBtn()}
      {renderAsMobile && toggleMenuBtn()}
      {!renderAsMobile && showSearch && <Search setShowSearch={setShowSearch} />}
    </div>
  );
}
export default QuickLinks;
